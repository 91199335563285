export const navSideData = [
  {
    routerLink: ['', 'home'],
    icon: 'fal fa-home',
    label: 'admin.home',
  },
  {
    routerLink: ['', 'expertises'],
    icon: 'fal fas fa-tools',
    label: 'admin.services',
  },
  {
    routerLink: ['', 'projects'],
    icon: 'fal fa-regular fa-folder-open',
    label: 'admin.projects',
  },
  {
    routerLink: ['', 'news'],
    icon: 'fal fa-regular fa-newspaper',
    label: 'admin.news',
  },
  {
    routerLink: ['', 'careers'],
    icon: 'fal fa-solid fa-briefcase',
    label: 'admin.careers',
  },
  {
    routerLink: ['', 'policy'],
    icon: 'fal fas fa-book',
    label: 'admin.policy',
  },
  {
    routerLink: ['', 'leadership'],
    icon: 'fal fas fa-user-tie',
    label: 'admin.leadership',
  },
  {
    routerLink: ['', 'story'],
    icon: 'fal fas fa-history',
    label: 'admin.story',
  },
  {
    routerLink: ['', 'contacts'],
    icon: 'fal fas fa-map-marked-alt',
    label: 'admin.contacts',
  },
  {
    routerLink: ['', '/admin'],
    icon: 'fal fa-user',
    label: 'admin.profile',
  },
];
