import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { AppState } from '../../app-state';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(label: string): Observable<string> {
    return this.store
      .select((state) => state.globalState.translations)
      .pipe(
        map((data) => {
          const labelArray = label.split('.');
          let currentData = data;
          for (const item of labelArray) {
            if (currentData[item]) {
              currentData = currentData[item];
            } else {
              currentData = '';
            }
          }
          return currentData;
        })
      );
  }
}
