import { createAction, props } from '@ngrx/store';
import { Project } from '../../features/projects/interfaces/project.interface';
import {
  ActiveDetails,
  Career,
  EmailForm,
  Expertise,
  News,
  Story,
} from '../../shared/interfaces/shared-interface';

export const loadTranslation = createAction(
  '[TRANSLATION] Load Translation',
  props<{ language: any }>()
);
export const loadTranslationSuccess = createAction(
  '[TRANSLATION] Load Translation Success',
  props<{ data: any }>()
);
export const loadTranslationError = createAction(
  '[TRANSLATION] Load Translation Error'
);

export const sendEmail = createAction(
  '[EMAIL] Send Email]',
  props<{ emailData: EmailForm }>()
);
export const sendEmailSuccess = createAction('[EMAIL] Send Email Success]');
export const sendEmailError = createAction(
  '[EMAIL] Send Email Error]',
  props<{ error: Error }>()
);

export const sendResume = createAction(
  '[EMAIL] Send Resume]',
  props<{ formData: any }>()
);
export const sendResumeSuccess = createAction('[EMAIL] Send Resume Success]');
export const sendResumeError = createAction(
  '[EMAIL] Send Resume Error]',
  props<{ error: Error }>()
);

export const fetchAllProjects = createAction('[GLOBAL] Fetch All Projects');
export const fetchAllProjectsSuccess = createAction(
  '[GLOBAL] Fetch All Projects Success',
  props<{ response: Project[] }>()
);
export const fetchAllProjectsError = createAction(
  '[GLOBAL] Fetch All Projects Error',
  props<{ error: Error }>()
);

export const fetchProfile = createAction('[Global] User Profile');
export const fetchProfileSuccess = createAction(
  '[GLOBAL] User Profile Success',
  props<{ payload: any }>()
);
export const fetchProfileError = createAction(
  '[GLOBAL] User Profile Error',
  props<{ error: Error }>()
);
export const fetchAllExpertises = createAction('[Global] Fetch All Expertises');
export const fetchAllExpertisesSuccess = createAction(
  '[GLOBAL] Fetch All Expertises Success',
  props<{ response: Expertise[] }>()
);
export const fetchAllExpertisesError = createAction(
  '[GLOBAL] Fetch All Expertises Error',
  props<{ error: Error }>()
);

export const fetchActiveDetails = createAction(
  '[GLOBAL] Fetch All Active Details'
);
export const fetchActiveDetailsSuccess = createAction(
  '[GLOBAL] Fetch All Active Details Success',
  props<{ response: ActiveDetails }>()
);
export const fetchActiveDetailsError = createAction(
  '[GLOBAL] Fetch All Active Details Error',
  props<{ error: Error }>()
);

export const deleteMedia = createAction(
  '[GLOBAL] Delete Media',
  props<{ existMedia: string }>()
);
export const deleteMediaSuccess = createAction(
  '[GLOBAL] Delete Media Success',
  props<{ response: any }>()
);
export const deleteMediaError = createAction(
  '[GLOBAL] Delete Media Error',
  props<{ error: Error }>()
);

export const fetchAllNews = createAction('[GLOBAL] Fetch all news');
export const fetchAllNewsSuccess = createAction(
  '[GLOBAL] Fetch all news Success',
  props<{
    response: News[];
  }>()
);
export const fetchAllNewsError = createAction(
  '[GLOBAL] Fetch all news Error',
  props<{ error: Error }>()
);

export const fetchAllCareers = createAction('[GLOBAL] Fetch  Careers');
export const fetchAllCareersSuccess = createAction(
  '[GLOBAL] Fetch Careers Success',
  props<{ response: Career[] }>()
);
export const fetchAllCareersError = createAction(
  '[GLOBAL] Fetch Careers Error',
  props<{ err: Error }>()
);

export const deleteDetails = createAction(
  '[GLOBAL] Delete Details',
  props<{ detailsId: number; nameList: string }>()
);
export const deleteDetailsSuccess = createAction(
  '[GLOBAL] Delete Details Success',
  props<{ response: any }>()
);
export const deleteDetailsError = createAction(
  '[GLOBAL] Delete Details Error',
  props<{ error: Error }>()
);

export const createDetails = createAction(
  '[GLOBAL] Create Details',
  props<{ details: any }>()
);
export const createDetailsSuccess = createAction(
  '[GLOBAL] Create Details Success',
  props<{ response: any }>()
);
export const createDetailsError = createAction(
  '[GLOBAL] Create Details Error',
  props<{ error: Error }>()
);

export const loadVideo = createAction(
  '[GLOBAL] Load Video',
  props<{ fileName: string }>()
);

export const loadVideoSuccess = createAction(
  '[GLOBAL] Load Video Success',
  props<{ video: any }>()
);

export const loadVideoFailure = createAction(
  '[GLOBAL] Load Video Failure',
  props<{ error: any }>()
);

export const fetchStory = createAction('[GLOBAL] Fetch All Story');
export const fetchStorySuccess = createAction(
  '[GLOBAL] Fetch All Story Success',
  props<{ response: Story[] }>()
);
export const fetchStoryError = createAction(
  '[GLOBAL] Fetch All Story Error',
  props<{ error: Error }>()
);

export const fetchLeadership = createAction('[GLOBAL] Fetch All Leadership');
export const fetchLeadershipSuccess = createAction(
  '[GLOBAL] Fetch All Leadership Success',
  props<{ response: any }>()
);
export const fetchLeadershipError = createAction(
  '[GLOBAL] Fetch All Leadership Error',
  props<{ error: Error }>()
);

export const fetchPolicy = createAction('[GLOBAL] Fetch All Policy');
export const fetchPolicySuccess = createAction(
  '[GLOBAL] Fetch All Policy Success',
  props<{ response: any }>()
);
export const fetchPolicyError = createAction(
  '[GLOBAL] Fetch All Policy Error',
  props<{ error: Error }>()
);

export const fetchContacts = createAction('[GLOBAL] Fetch All Contacts');
export const fetchContactsSuccess = createAction(
  '[GLOBAL] Fetch All Contacts Success',
  props<{ response: any }>()
);
export const fetchContactsError = createAction(
  '[GLOBAL] Fetch All Contacts Error',
  props<{ error: Error }>()
);
