<div
  class="sidenav hidden lg:block md:block sm:block"
  [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
  <div class="logo-container">
    <button class="logo" (click)="toggleCollapse()">
      <img src="assets/icons/dashboard-menu.svg" />
    </button>
    <div *ngIf="collapsed" class="logo-text" @fadeInOut>
      {{ 'admin.dashboard' | translate | async }}
    </div>
    <button
      *ngIf="collapsed"
      class="btn-close"
      (click)="closeSideNav()"
      @iconInOut>
      <i class="fal fa-times close-icon"></i>
    </button>
  </div>
  <ul class="sidenav-nav mt-[40px]">
    <li *ngFor="let data of navData" class="sidenav-nav-item">
      <a
        class="sidenav-nav-link"
        [routerLink]="data.routerLink[1]"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="hightlight">
        <i class="sidenav-link-icon" [class]="data.icon"></i>
        <span *ngIf="collapsed" class="sidenav-link-text" @fadeInOut>{{
          data.label | translate | async
        }}</span>
      </a>
    </li>
  </ul>

  <div class="absolute top-[43%] w-[100%]">
    <ul class="logout-nav mt-[40px]">
      <li class="logout-nav-item cursor-pointer" (click)="getLogOut()">
        <a
          class="logout-nav-link ps-[3px]"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="hightlight">
          <label class="rotate cursor-pointer">
            <i class="fas fa-sign-out-alt text-[35px] ms-1"></i
          ></label>
          <span *ngIf="collapsed" class="logout-link-text ms-2" @fadeInOut>
            {{ 'admin.logout' | translate | async }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
