import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrl: './warning-message.component.scss',
})
export class WarningMessageComponent {
  @Input() warningMessage: string = '';
}
