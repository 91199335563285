import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Key } from '../../../../../global/enum/global.enum';
import { DeleteWarningMessageComponent } from '../../../delete-warning-message/delete-warning-message.component';
import { Language } from '../../interfaces/nav.interfaces';
import { navSideData } from '../../nav-data';

@Component({
  selector: 'app-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrl: './nav-side.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1300ms', style({ opacity: 2 })),
      ]),
      transition(':leave', [
        style({ opacity: 0 }),
        animate('0ms', style({ opacity: 1 })),
      ]),
    ]),
    trigger('iconInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1200ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class NavSideComponent implements OnInit, OnChanges {
  @Output() onToggleSideNav: EventEmitter<any> = new EventEmitter();
  @Output() languageSelection: EventEmitter<Language> = new EventEmitter();
  @Input() collapsed = false;
  public screenWidth = 0;
  public navData = navSideData;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    this.screenWidth = window.innerWidth;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
    if (this.screenWidth > 768) {
      this.collapsed = true;
      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
  }

  public toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  public closeSideNav(): void {
    this.collapsed = false;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  public getLogOut(): void {
    this.dialog
      .open(DeleteWarningMessageComponent, {
        data: {
          warningMessage: 'Do you want to logout?',
        },
      })
      .afterClosed()
      .subscribe((isApprove: any) => {
        if (!isApprove) {
          return;
        }
        localStorage.removeItem(Key.TOKEN);
        localStorage.removeItem(Key.REFRESH_TOKEN);

        setTimeout(() => {
          location.reload();
        }, 100);
      });
  }
}
