import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Link } from '../../../interfaces/shared-interface';
import { NAV_SIDE_DATA } from '../nav-data';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrl: './nav-header.component.scss',
})
export class NavHeaderComponent implements OnInit, OnDestroy {
  @Input() expertisesList: string[] = [];
  public navbarList: Link[] = NAV_SIDE_DATA;
  public isMenuOpen = false;
  public currentTitle: string = '';
  private titleIndex = 0;
  private intervalId: any;
  public isFadingOut = false;
  public isFadingIn = false;
  public isHomePage = true;
  public isMobileMenuActive = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.startTitleRotation();
    this.checkRouterPath();
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.checkRouterPath();
      this.isHomeActive();
    });
  }

  public isHomeActive(): boolean {
    const currentUrl = this.router.url;
    return !(
      currentUrl.includes('/about-us') ||
      currentUrl.includes('/projects') ||
      currentUrl.includes('/expertises') ||
      currentUrl.includes('/news') ||
      currentUrl.includes('/contact') ||
      currentUrl.includes('/careers')
    );
  }

  private startTitleRotation(): void {
    this.currentTitle = this.expertisesList[this.titleIndex];
    this.intervalId = setInterval(() => {
      this.isFadingOut = true;
      setTimeout(() => {
        this.titleIndex = (this.titleIndex + 1) % this.expertisesList.length;
        this.currentTitle = this.expertisesList[this.titleIndex];
        this.isFadingOut = false;
        this.isFadingIn = true;
      }, 500);
      setTimeout(() => {
        this.isFadingIn = false;
      }, 1000);
    }, 3000);
  }

  private stopTitleRotation(): void {
    clearInterval(this.intervalId);
  }

  @HostListener('window:scroll', ['$event'])
  private onScroll(event: Event): void {
    const navHome = document.querySelector('.top-nav-container') as HTMLElement;
    const nav = document.querySelector(
      '.top-nav-mobile-container'
    ) as HTMLElement;
    const currentUrl = this.router.url;
    if (
      currentUrl === '/expertises' ||
      currentUrl === '/about-us' ||
      currentUrl === '/projects' ||
      currentUrl === '/news' ||
      currentUrl === '/careers' ||
      currentUrl === '/contact' ||
      currentUrl === '/' ||
      currentUrl.startsWith('/projects/project') ||
      currentUrl.startsWith('/about-us/about') ||
      currentUrl.startsWith('/expertises/expertise') ||
      currentUrl.startsWith('/news/articles') ||
      currentUrl.startsWith('/careers/career')
    ) {
      if (window.scrollY > 50) {
        navHome.classList.add('bg-scroll');
        nav.classList.add('bg-scroll');
      } else {
        navHome.classList.remove('bg-scroll');
        nav.classList.remove('bg-scroll');
      }
    }
  }

  public toggleMenu(): void {
    this.isMobileMenuActive = false;
    setTimeout(() => {
      this.isMobileMenuActive = true;
    }, 100);
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeStyle(document.body, 'overflow');
    }
  }

  private checkRouterPath(): void {
    const navHome = document.querySelector('.top-nav-container') as HTMLElement;
    const nav = document.querySelector(
      '.top-nav-mobile-container'
    ) as HTMLElement;
    const currentUrl = this.router.url;
    this.isHomePage = !(
      currentUrl === '/' ||
      currentUrl === '/contact' ||
      currentUrl.startsWith('/projects/project') ||
      currentUrl.startsWith('/expertises/expertise') ||
      currentUrl.startsWith('/careers/career')
    );
    navHome.classList.remove('bg-scroll');
    nav.classList.remove('bg-scroll');
  }

  ngOnDestroy() {
    this.stopTitleRotation();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
