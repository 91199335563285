<div class="bg-[#ffffff] pb-16">
  <h1
    *ngIf="!isListOfColumnEmpty"
    class="lg:text-[48px] md:text-[42px] sm:text-[36px] text-[32px] font-medium text-center">
    Projects
  </h1>

  <div
    *ngIf="!isListOfColumnEmpty"
    class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 p-4">
    @for (column of columns; track column) {
      <div class="grid gap-4">
        <div
          *ngFor="let item of listOfColumnItems[column]"
          class="image-container"
          [routerLink]="'/projects/project/' + item.id + '/' + item.title">
          <div class="image-container-example">
            <h1
              class="image-label mt-10 ms-2 lg:text-[30px] md:text-[28px] sm:text-[24px] text-[24px] font-bold absolute text-[#FFFFFF]">
              {{ item.title | truncate: 30 }}
            </h1>
            <img
              class="image h-auto w-auto"
              [src]="environment.assets + item?.imageName"
              alt="" />
            <div class="overlay"></div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
