import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'app-delete-warning-message',
  templateUrl: './delete-warning-message.component.html',
  styleUrl: './delete-warning-message.component.scss',
})
export class DeleteWarningMessageComponent implements OnInit {
  public warningMessage!: string;

  constructor(
    private dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.warningMessage = this.data.warningMessage;
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public approveProcess(): void {
    this.dialogRef.close(true);
  }
}
