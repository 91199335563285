export const LANGUAGES = [
  {
    name: 'English',
    value: 'en',
    flag: 'fi fi-gb',
  },
  {
    name: 'Albanian',
    value: 'al',
    flag: 'fi fi-al',
  },
];

export const MASONRY_COLUMNS = [
  'firstColumn',
  'secondColumn',
  'thirdColumn',
  'fourthColumn',
];

export const IMG_FILE_TYPE = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
export const IMG_VIDEO_FILE_TYPE = ['png', 'jpg', 'jpeg', 'webp', 'gif', 'mp4'];
export const VIDEO_FILE_TYPE = ['mp4'];
export const PDF_FILE_TYPE = ['pdf'];
