import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../app-state';
import { Project } from '../../features/projects/interfaces/project.interface';
import {
  ActiveDetails,
  Career,
  Contact,
  EmailForm,
  Expertise,
  News,
} from '../../shared/interfaces/shared-interface';
import * as GlobalActions from './../store/global.actions';

@Injectable()
export class GlobalStoreService {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  public sendEmail(emailData: EmailForm): void {
    this.store.dispatch(GlobalActions.sendEmail({ emailData }));
  }

  public sendResume(formData: any): void {
    this.store.dispatch(GlobalActions.sendResume({ formData }));
  }

  public loadingEmail(): Observable<boolean> {
    return this.store.select((s) => s.globalState.emailLoading);
  }

  public fetchAllProjects(): void {
    this.store.dispatch(GlobalActions.fetchAllProjects());
  }

  public selectAllProjects(): Observable<Project[]> {
    return this.store.select((s) => s.globalState.projectsList);
  }

  public fetchUserProfile(): void {
    this.store.dispatch(GlobalActions.fetchProfile());
  }

  public selectUserProfile(): Observable<any> {
    return this.store.select((s) => s.globalState.profile);
  }

  public fetchAllExpertises(): void {
    this.store.dispatch(GlobalActions.fetchAllExpertises());
  }

  public selectAllExpertises(): Observable<Expertise[]> {
    return this.store.select((s) => s.globalState.expertisesList);
  }

  public fetchActiveDetails(): void {
    this.store.dispatch(GlobalActions.fetchActiveDetails());
  }

  public selectActiveDetails(): Observable<ActiveDetails> {
    return this.store.select((s) => s.globalState.activeDetails);
  }

  public deleteMedia(existMedia: string): void {
    this.store.dispatch(GlobalActions.deleteMedia({ existMedia }));
  }

  public fetchAllNews(): void {
    this.store.dispatch(GlobalActions.fetchAllNews());
  }

  public selectAllNews(): Observable<News[]> {
    return this.store.select((s) => s.globalState.newsList);
  }

  public fetchAllCareers(): void {
    this.store.dispatch(GlobalActions.fetchAllCareers());
  }

  public selectAllCareers(): Observable<Career[]> {
    return this.store.select((s) => s.globalState.careers);
  }

  public deleteDetails(detailsId: number, nameList: string): void {
    this.store.dispatch(GlobalActions.deleteDetails({ detailsId, nameList }));
  }

  public createDetails(details: any): void {
    this.store.dispatch(GlobalActions.createDetails({ details }));
  }

  public selectLoading(): Observable<boolean> {
    return this.store.select((s) => s.globalState.isLoading);
  }

  public fetchStory(): void {
    this.store.dispatch(GlobalActions.fetchStory());
  }

  public selectStory(): Observable<any> {
    return this.store.select((s) => s.globalState.story);
  }

  public fetchLeadership(): void {
    this.store.dispatch(GlobalActions.fetchLeadership());
  }

  public selectLeadership(): Observable<any> {
    return this.store.select((s) => s.globalState.leadership);
  }

  public fetchPolicy(): void {
    this.store.dispatch(GlobalActions.fetchPolicy());
  }

  public selectPolicy(): Observable<any> {
    return this.store.select((s) => s.globalState.policy);
  }

  public fetchContacts(): void {
    this.store.dispatch(GlobalActions.fetchContacts());
  }

  public selectContacts(): Observable<Contact[]> {
    return this.store.select((s) => s.globalState.contactsList);
  }

  public selectProfileError(): Observable<any> {
    return this.store.select((s) => s.globalState.error);
  }

  public loadVideo(fileName: string): void {
    this.store.dispatch(GlobalActions.loadVideo({ fileName }));
  }

  public selectLoadVideo(): Observable<any> {
    return this.store.select((s) => s.globalState.video);
  }

  public selectUpdateRequired(): Observable<any> {
    return this.actions$.pipe(ofType(GlobalActions.loadVideoSuccess));
  }
}
